<template>
  <div class="card-group mt-4">
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Основное</h3>

        <ul class="list-group">
          <a
            href="/admin-dashboard/authors"
            @click.prevent="
              this.$router.push({
                name: 'admin-authors',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Авторы
            <span class="badge badge-primary badge-pill">{{
              dto.authorsCount
            }}</span>
          </a>
          <a
            href="/admin-dashboard/mods"
            @click.prevent="
              this.$router.push({
                name: 'admin-mods',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Моды


            <div>
            <span v-if="dto.inactiveModsCount > 0" class="badge badge-warning badge-pill" style="margin-right: 4px;">{{
                dto.inactiveModsCount
              }}</span>

            <span class="badge badge-primary badge-pill">{{
              dto.modsCount
            }}</span>
            </div>
          </a>
          <a
            href="/admin-dashboard/tags"
            @click.prevent="
              this.$router.push({
                name: 'admin-tags',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Теги
            <span class="badge badge-primary badge-pill">{{
              dto.tagsCount
            }}</span>
          </a>

          <a
              href="/admin-dashboard/articles"
              @click.prevent="
              this.$router.push({
                name: 'admin-articles',
                params: {},
              })
            "
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Статьи
            <span class="badge badge-primary badge-pill">{{
                dto.articlesCount
              }}</span>
          </a>
        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Запросы</h3>

        <ul class="list-group">
          <a
            href="/admin-dashboard/mod-requests"
            @click.prevent="
              this.$router.push({
                name: 'mod-requests',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Запросы на модификацию
            <span class="badge badge-primary badge-pill">{{
              dto.modRequests
            }}</span>
          </a>
          <a
            href="/admin-dashboard/translation-requests"
            @click.prevent="
              this.$router.push({
                name: 'mod-translations',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Запросы на перевод
            <span class="badge badge-primary badge-pill">{{
              dto.translationsRequests
            }}</span>
          </a>
          <a
              href="/admin-dashboard/translation-requests"
              @click.prevent="
              this.$router.push({
                name: 'admin-searches',
                params: {},
              })
            "
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Лог поисковых запросов
            <span class="badge badge-primary badge-pill">{{
                dto.searchesCount
              }}</span>
          </a>

        </ul>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Администрирование</h3>

        <ul class="list-group">
          <a
            href="/admin-dashboard/users"
            @click.prevent="
              this.$router.push({
                name: 'admin-users',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Пользователи
          </a>
          <a
            href="/admin-dashboard/properties"
            @click.prevent="
              this.$router.push({
                name: 'properties',
                params: {},
              })
            "
            class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Свойства
          </a>
          <a
              href="/admin-dashboard/roles"
              @click.prevent="
              this.$router.push({
                name: 'roles',
                params: {},
              })
            "
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Роли
          </a>
          <a
              href="/admin-dashboard/properties"
              @click.prevent="
              this.$router.push({
                name: 'tokens',
                params: {},
              })
            "
              class="list-group-item d-flex justify-content-between align-items-center list-group-item-action"
          >
            Токены
          </a>
        </ul>
      </div>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-body">
      <h3 class="card-title">Календарь</h3>

      <div class="list-group mt-5">
        <li v-if="false"
          :class="{
            'list-group-item': true,
            'list-group-item-action': true,
            'list-group-item-warning':
              getDays(dto.fileHostingExpireDate) < 60 &&
              getDays(dto.fileHostingExpireDate) > 30,
            'list-group-item-danger': getDays(dto.fileHostingExpireDate) <= 30,
            'list-group-item-success': getDays(dto.fileHostingExpireDate) > 60,
          }"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Mediafire</h3>
          </div>

          <p class="mb-1">
            До окончания работы хранилища файлов осталось
            {{ getDays(dto.fileHostingExpireDate) }} дней.
          </p>
          <small>Истекает: {{ formatDate(dto.fileHostingExpireDate) }}</small>
        </li>

          <li
              :class="{
            'list-group-item': true,
            'list-group-item-action': true,
            'list-group-item-warning':
              getDays(dto.serverExpireDate) < 60 &&
              getDays(dto.serverExpireDate) > 30,
            'list-group-item-danger': getDays(dto.serverExpireDate) <= 30,
            'list-group-item-success': getDays(dto.serverExpireDate) > 60,
          }"
          >
            <div class="d-flex w-100 justify-content-between">
              <h3 class="mb-1">Сервер</h3>
            </div>

            <p class="mb-1">
              До окончания работы сервера осталось
              {{ getDays(dto.serverExpireDate) }} дней.
            </p>
            <small>Истекает: {{ formatDate(dto.serverExpireDate) }}</small>
          </li>


        <li
          :class="{
            'list-group-item': true,
            'list-group-item-action': true,
            'list-group-item-warning':
              getDays(dto.domainExpireDate) < 60 &&
              getDays(dto.domainExpireDate) > 30,
            'list-group-item-danger': getDays(dto.domainExpireDate) <= 30,
            'list-group-item-success': getDays(dto.domainExpireDate) > 60,
          }"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Домен</h3>
          </div>

          <p class="mb-1">
            До окончания работы домена осталось
            {{ getDays(dto.domainExpireDate) }} дней.
          </p>
          <small>Истекает: {{ formatDate(dto.domainExpireDate) }}</small>
        </li>
      </div>
    </div>
  </div>

  <div class="card mt-4">
    <div class="card-body">
      <h3 class="card-title">Работа</h3>

      <div class="list-group mt-5">
        <a
          href="/admin-dashboard/add-tags"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Добавьте теги к материалам</h3>
            <span class="badge badge-primary badge-pill">{{
              dto.addTagMaterials
            }}</span>
          </div>
          <p class="mb-1">У этих материалов недостаточно тегов (меньше 3).</p>
        </a>

        <a
          href="/admin-dashboard/add-tags"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Проверьте орфографию</h3>
            <span class="badge badge-primary badge-pill">{{
              dto.addTagMaterials
            }}</span>
          </div>
          <p class="mb-1">
            Проверьте пунктуационные и орфографические ошибки в описаниях
          </p>
        </a>

        <a
          href="/admin-dashboard/add-translations"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Добавьте перевод к материалам</h3>
            <span class="badge badge-primary badge-pill">{{
              dto.translationMaterials
            }}</span>
          </div>
          <p class="mb-1">
            У этих материалов нет перевода на английский язык. Самое время
            попросить ChatGPT перевести за вас, либо потренироваться самому...
          </p>
        </a>

        <a
          href="/admin-dashboard/add-screenshots"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">Добавьте больше скриншотов к материалам</h3>
            <span class="badge badge-primary badge-pill">{{
              dto.screenshotMaterials
            }}</span>
          </div>
          <p class="mb-1">
            У этих материалов всего один скриншот. Можно добавить побольше,
            особенно к своим работам (huehuehue).
          </p>
        </a>

        <a
          href="/admin-dashboard/find-author"
          class="list-group-item list-group-item-action"
        >
          <div class="d-flex w-100 justify-content-between">
            <h3 class="mb-1">У материала неизвестный автор</h3>
            <span class="badge badge-primary badge-pill">{{
              dto.unknownAuthorMaterials
            }}</span>
          </div>
          <p class="mb-1">
            Проведите собственное расследование и найдите утраченное авторство.
          </p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import ApiService from "../../core/services/ApiService";

export default defineComponent({
  name: "admin-dashboard",
  components: {},
  data: function () {
    return {
      dto: {
        authorsCount: 0,
        modsCount: 0,
        inactiveModsCount: 0,
        tagsCount: 0,
        articlesCount: 0,

        modRequests: 0,
        translationsRequests: 0,
        searchesCount: 0,

        addTagMaterials: 0,
        translationMaterials: 0,
        screenshotMaterials: 0,
        youtubeVideoMaterials: 0,
        unknownAuthorMaterials: 0,
        fileHostingExpireDate: "",
        domainExpireDate: "",
        serverExpireDate: "",
      },
    };
  },

  created: async function () {
    await ApiService.get("api", `admin/dashboard`)
      .then((response) => {
        this.dto = response.data;
      })
      .catch(() => {
        console.log("redirect");
      });
  },

  methods: {
    formatDate(dateString) {
      if (!dateString) return "Ошибка разбора даты";

      const months = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

      const date = new Date(dateString);
      const day = date.getDate();
      const month = months[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${month} ${year}`;
    },

    getDays(dateString) {
      if (!dateString) return "Ошибка разбора строки";

      const givenDate = new Date(dateString);
      const currentDate = new Date();

      // Получаем разницу в миллисекундах
      const differenceInMilliseconds = givenDate - currentDate;

      // Преобразуем миллисекунды в дни
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

      return Math.ceil(differenceInDays); // Округляем до ближайшего целого вверх
    },
  },
});
</script>
